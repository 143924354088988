<template>
  <div class="sidebar-new">
    <div class="brand-tools pt-4" v-if="allowMinimize">
      <button
        class="brand-toggle btn btn-sm px-0"
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            :src="$assetURL('media/svg/icons/Navigation/Angle-double-left.svg')"
          />
        </span>
      </button>
    </div>
    <div
      class="user-menu"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
      "
    >
      <div style="text-align: center; color: #fff">
        <v-avatar
          color="blue"
          style="background: white; border: 2px solid #0a5def"
          size="56"
        >
          <v-img :lazy-src="$defaultProfileImage" :src="profileImage"></v-img>
        </v-avatar>
        <h5 style="margin-top: 8px">{{ user.display_name }}</h5>
        <p>{{ user.user_email }}</p>
      </div>
    </div>
    <ul class="menu-nav">
      <div class="before-element"></div>
      <template v-for="(row, index) in MenuList">
        <template v-if="lodash.isArray(row)">
          <li
            v-bind:key="index"
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item menu-item-submenu"
            :class="{
              'menu-item-open menu-item-open-active': isChildActive(row),
            }"
          >
            <a href="#" class="menu-link menu-toggle">
              <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="
                    $assetURL('media/custom-svg/' + getGroupTitle(row) + '.svg')
                  "
                />
                <!--end::Svg Icon-->
              </span>
              <span class="menu-text text-capitalize">{{
                getGroupTitle(row)
              }}</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <li aria-haspopup="true" class="menu-item menu-item-parent">
                  <span class="menu-link">
                    <span class="menu-text text-capitalize">{{
                      getGroupTitle(row)
                    }}</span>
                  </span>
                </li>
                <template v-for="(rowd, indexd) in row">
                  <router-link
                    v-bind:key="indexd"
                    v-bind:to="rowd.route"
                    v-bind:class="{
                      'menu-item-active': isRouteActive(rowd.menu),
                      'disable-link': disableLink(rowd.menu),
                    }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                    >
                      <a :href="href" class="menu-link" v-on:click="navigate">
                        <i class="menu-bullet menu-bullet-dot">
                          <span></span>
                        </i>
                        <span class="menu-text">{{ rowd.title }}</span>
                      </a>
                    </li>
                  </router-link>
                </template>
              </ul>
            </div>
          </li>
        </template>
        <router-link
          v-else
          v-bind:key="index"
          v-bind:to="row.route"
          v-bind:class="{
            'menu-item-prev': isPrevActive(row.order, row.menu),
            'menu-item-next': isNextActive(row.order, row.menu),
            'menu-item-active': isRouteActive(row.menu),
            'disable-link': disableLink(row.menu),
          }"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a :href="href" class="menu-link" v-on:click="navigate">
              <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + row.icon + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              <span
                class="menu-text"
                v-if="row.menu != 'dashboard' && row.menu != 'calendar'"
                >{{ getMenuTitle(row.title) }}</span
              >
              <span class="menu-text" v-else>{{ row.title }}</span>
            </a>
          </li>
        </router-link>
      </template>
      <!-- <li
        v-for="(row, index) in reports"
        v-bind:key="index"
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        :class="{
          'menu-item-open menu-item-open-active': isChildActive(row),
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <span class="svg-icon svg-icon-lg svg-custom-menu-icon mr-4">
            <inline-svg
              :src="$assetURL('media/custom-svg/' + getGroupTitle(row) + '.svg')"
            />
          </span>
          <span class="menu-text text-capitalize">{{ getGroupTitle(row) }}</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <li aria-haspopup="true" class="menu-item menu-item-parent">
              <span class="menu-link">
                <span class="menu-text text-capitalize">{{
                  getGroupTitle(row)
                }}</span>
              </span>
            </li>
            <template v-for="(rowd, indexd) in row">
              <router-link
                v-bind:key="indexd"
                v-bind:to="rowd.route"
                v-bind:class="{
                  'menu-item-active': isRouteActive(rowd.menu),
                  'disable-link': disableLink(rowd.menu),
                }"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                >
                  <a :href="href" class="menu-link" v-on:click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ rowd.title }}</span>
                  </a>
                </li>
              </router-link>
            </template>
          </ul>
        </div>
      </li> -->
      <div class="after-element"></div>
    </ul>
    <div class="sidebar-bottom-img" style="">
      <v-img
        :src="$assetURL('media/custom-svg/networking.png')"
        heigh="100px"
        width="100%"
      ></v-img>
    </div>
    <div class="bottom-bg-svg"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import JwtService from "@/core/services/jwt.service";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import inflect from "i";
import ObjectPath from "object-path";

export default {
  name: "KTMenu",
  data() {
    return {
      MenuList: [],
      activeMenuI: 0,
      profileImage: null,
      user: {},
      /* reports: {
        report: [
          {
            title: "Projects",
            icon: "project",
            menu: "project-report",
            route: {
              name: "admin.report.project",
            },
            group: "report",
            order: 17,
          },
          {
            title: "Sales",
            icon: "sales",
            menu: "sales-report",
            route: {
              name: "admin.report.sales",
            },
            group: "report",
            order: 18,
          },
          {
            title: "Invoices",
            icon: "invoices",
            menu: "invoices-report",
            route: {
              name: "admin.report.invoices",
            },
            group: "report",
            order: 19,
          },
        ],
      }, */
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
  watch: {
    $route() {
      this.activeMenuI = 9999;
    },
  },
  methods: {
    isPrevActive(param, menu) {
      let isActive = this.isRouteActive(menu);
      if (isActive) {
        this.activeMenuI = param;
      }

      if (this.activeMenuI - 1 == param) {
        return true;
      }

      return false;
    },
    isNextActive(param, menu) {
      let isActive = this.isRouteActive(menu);
      if (isActive) {
        this.activeMenuI = param;
      }

      if (this.activeMenuI + 1 == param) {
        return true;
      }

      return false;
    },
    isChildActive(row) {
      let isActive = false;
      for (let i = 0; i < row.length; i++) {
        isActive = this.isRouteActive(row[i].menu);
        if (isActive) {
          return isActive;
        }
      }
      return isActive;
    },
    getMenuTitle(title) {
      if (title == "CaseTrust") {
        return title;
      }
      if (title == "Packages DB") {
        return title;
      }
      if (title == "Additional Works DB") {
        return title;
      }
      return inflect().pluralize(title);
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }
      return false;
    },
    disableLink(match) {
      if (this.lodash.isEmpty(this.$route.meta) === false) {
        return (
          this.$route.meta.menuActive === match && this.$route.meta.isListing
        );
      }
      return false;
    },
    getGroupTitle(row) {
      for (let i = 0; i < row.length; i++) {
        if (row[i].group) {
          return row[i].group;
        }
      }
    },
    appendMenu() {
      /*  const length = this.MenuList.length+1;
      console.log("length",length);
      this.MenuList.push({
          "title": "",
          "icon": "",
          "menu": "",
          "route": {
             
          },
          "group": null,
          "order": length
      }) */
    },
    getMenuList() {
      const _this = this;
      try {
        const result = JwtService.menuList();
        const arrays = [];
        const objects = [];
        const topTwo = [];
        const lastOne = [];
        for (const menu in result) {
          if (_this.lodash.isArray(result[menu])) {
            arrays.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order <= 1
          ) {
            topTwo.push(result[menu]);
          } else if (
            _this.lodash.isObject(result[menu]) &&
            result[menu].order >= 21
          ) {
            lastOne.push(result[menu]);
          } else if (_this.lodash.isObject(result[menu])) {
            objects.push(result[menu]);
          }
        }
        _this.MenuList = [...topTwo, ...arrays, ...objects, ...lastOne];
        _this.appendMenu();
        if (_this.lodash.isEmpty(_this.MenuList)) {
          JwtService.destroyToken();
        }
      } catch (error) {
        _this.logError(error);
        JwtService.destroyToken();
      }
    },
  },
  mounted() {
    this.getMenuList();
    this.user = JwtService.currentUser();
    this.profileImage = ObjectPath.get(this.user, "profile_logo.file.url");
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
};
</script>
